import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./tfnsw.css"; // This is only for development.
import "./gotham.css"; // This is only for development.
import App from "./App";
import * as serviceWorker from "./modules/serviceWorker";
import { ThemeProvider } from "emotion-theming";
import theme from "./style/theme";
import { Provider } from "react-redux";
import createStore from "./store";

const store = createStore({
    app: { env: "dev" },
});

const upgradeId = window.location.hash.replace("#", "") || 1;

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <App upgradeId={upgradeId} />
        </Provider>
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
