import React, { Component } from "react";
import * as Components from "./station-map.style";

import { getUrl } from "../../modules/make-request";
const getMapImageUrl = (upgrade, env) => getUrl(`/upgrades/${upgrade.id}/station-map`, env)

class StationMap extends Component {
    renderStationMap(upgrade) {
        const { env } = this.props;
        return (
            <Components.StationMapImage src={getMapImageUrl(upgrade, env)} alt="Station map"/>
        )
    }

    renderMarkers() {
        const { upgrade } = this.props;
        return (upgrade.components || []).map((component, index) => {
            const x = component.station_map_x || 0.5;
            const y = component.station_map_y || 0.5;

            return (
                <Components.StationMapMarker
                    key={component.id}
                    left={x * 100 + "%"}
                    top={y * 100 + "%"}
                    onClick={this.props.onMarkerClick ? () => this.props.onMarkerClick(component) : null}
                >
                    {index + 1}
                </Components.StationMapMarker>
            );
        });
    }

    render() {
        const { upgrade } = this.props;
        return (
            <Components.StationMap>
                {this.renderStationMap(upgrade)}
                {this.renderMarkers()}
            </Components.StationMap>
        );
    }
}

export default StationMap;
