import * as THREE from "three";
import { getUrl } from "../../modules/make-request";
import Animation from "../../modules/animation";
import DeviceOrientationControls from "../../modules/device-orientation-controls";

const SPHERE_RADIUS = 500;
const MIN_FOV = 50;
const MAX_FOV = 100;
const MOMENTUM_DECAY = 0.85;
const MOMENTUM_PRESERVED = 0.3;
const MOMENTUM_STOP = 0.00001;

const getPanoramaImageUrl = (panorama, env) => getUrl(`/panoramas/${panorama.id}/image`, env)

export default class ThreePanoScene {
    isUserInteracting = false;
    lon = 0;
    lat = Math.PI / 2;
    controlEnabled = true;
    fullscreen = false;
    deviceOrientationControlsEnabled = false;

    momentumX = 0;
    momentumY = 0;

    constructor(canvas, container, onNewCoords, props) {
        this.canvas = canvas;
        this.container = container;
        this.onNewCoords = onNewCoords;
        this.distortion = props.distortion;

        const containerSize = container.getBoundingClientRect();
        const { width, height } = containerSize;

        this.camera = new THREE.PerspectiveCamera((MIN_FOV + MAX_FOV) / 2, width / height, 1, SPHERE_RADIUS * 2);
        this.camera.target = new THREE.Vector3(0, 0, 0);

        this.scene = new THREE.Scene();

        const geometry = new THREE.SphereGeometry(SPHERE_RADIUS, 60, 40);
        geometry.applyMatrix(new THREE.Matrix4().makeScale(-1, 1, 1));

        const texture = new THREE.TextureLoader().load(getPanoramaImageUrl(props.panorama, props.env));

        const material = new THREE.MeshBasicMaterial({
            map: texture,
        });

        const mesh = new THREE.Mesh(geometry, material);

        this.scene.add(mesh);

        this.renderer = new THREE.WebGLRenderer({
            canvas,
        });
        this.renderer.setSize(width, height);

        this.deviceOrientationControls = new DeviceOrientationControls(this.camera);

        canvas.addEventListener("mousedown", this.onCanvasMouseDown, false);
        canvas.addEventListener("mousemove", this.onCanvasMouseMove, false);

        canvas.addEventListener("touchstart", this.onCanvasMouseDown, false);
        canvas.addEventListener("touchmove", this.onCanvasMouseMove, false);

        canvas.addEventListener("mouseup", this.onCanvasMouseUp, false);
        canvas.addEventListener("touchend", this.onCanvasMouseUp, false);

        canvas.addEventListener("mousewheel", this.onCanvasMouseWheel, false);
        canvas.addEventListener("DOMMouseScroll", this.onCanvasMouseWheel, false);

        window.addEventListener("resize", this.onWindowResize, false);
        document.addEventListener("fullscreenchange", this.onWindowResize, false);

        this.cameraTargetAnimation = new Animation(0.1, [
            { from: 0, to: 0 },
            { from: Math.PI / 2, to: Math.PI / 2 },
        ]);
    }

    cleanup() {
        this.canvas.removeEventListener("mousedown", this.onCanvasMouseDown);
        this.canvas.removeEventListener("touchstart", this.onCanvasMouseDown);

        this.canvas.removeEventListener("mousemove", this.onCanvasMouseMove);
        this.canvas.removeEventListener("touchmove", this.onCanvasMouseMove);

        this.canvas.removeEventListener("mouseup", this.onCanvasMouseUp);
        this.canvas.removeEventListener("touchend", this.onCanvasMouseUp);

        this.canvas.removeEventListener("mousewheel", this.onCanvasMouseWheel);
        this.canvas.removeEventListener("DOMMouseScroll", this.onCanvasMouseWheel);

        window.removeEventListener("resize", this.onWindowResize);
        document.removeEventListener("fullscreenchange", this.onWindowResize);
    }

    start() {
        window.requestAnimationFrame(this.animate);
    }

    onWindowResize = () => {
        const containerSize = this.container.getBoundingClientRect();
        const { width, height } = containerSize;
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
    };

    getEventPosition = (e) => ({
        x: (e.clientX >= 0 ? e.clientX : e.touches[0].clientX),
        y: (e.clientY >= 0 ? e.clientY : e.touches[0].clientY),
    })

    onCanvasMouseDown = event => {
        const { x, y } = this.getEventPosition(event);

        event.preventDefault();

        this.isUserInteracting = true;
        this.cameraTargetAnimation.stop();

        this.onPointerDownPointerX = x;
        this.onPointerDownPointerY = y;

        this.onPointerDownLon = this.lon;
        this.onPointerDownLat = this.lat;
    };

    onCanvasMouseMove = event => {
        const { x, y } = this.getEventPosition(event);
        if (this.isUserInteracting === true && this.controlEnabled && this.fullscreen) {

            const previousLon = this.lon;
            const previousLat = this.lat;

            this.lon = this.onPointerDownLon + ((this.onPointerDownPointerX - x) * 0.003);
            this.lat = this.onPointerDownLat - ((y - this.onPointerDownPointerY) * 0.003);
            this.lat = Math.max(0.1, Math.min(2.5, this.lat));
            this.onNewCoords({ lon: this.lon, lat: this.lat });

            // Maintain some momentum.
            this.momentumX *= MOMENTUM_PRESERVED;
            this.momentumX += (this.lon - previousLon);

            this.momentumY *= MOMENTUM_PRESERVED;
            this.momentumY += (this.lat - previousLat);
        }
    };

    onCanvasMouseUp = event => {
        this.isUserInteracting = false;
    };

    onCanvasMouseWheel = event => {
        event.stopPropagation();
        event.preventDefault();
        if (event.wheelDeltaY) {
            this.camera.fov -= event.wheelDeltaY * 0.05;
        } else if (event.wheelDelta) {
            this.camera.fov -= event.wheelDelta * 0.05;
        } else if (event.detail) {
            this.camera.fov += event.detail * 1.0;
        }

        this.camera.fov = Math.max(MIN_FOV, Math.min(this.camera.fov, MAX_FOV));
        this.camera.updateProjectionMatrix();
    };

    animate = (now) => {
        const dt = ((now - this.lastFrameTime) / 1000) || (1 / 60);
        this.lastFrameTime = now;
        window.requestAnimationFrame(this.animate);
        this.update(dt);
    };

    doAnimation(dt) {
        const cameraTargetUpdate = this.cameraTargetAnimation.update(dt);
        if (cameraTargetUpdate) {
            const [lon, lat] = cameraTargetUpdate;
            this.lon = lon;
            this.lat = lat; 
            this.onNewCoords({ lon: this.lon, lat: this.lat });
        }
    }

    update(dt) {
        this.doAnimation(dt);

        if (this.deviceOrientationControlsEnabled && this.fullscreen) {
            const orientationResult = this.deviceOrientationControls.update();
            if (orientationResult) {
                // X is looking up and down
                this.lat = -orientationResult.x + (Math.PI / 2);
                // Y is looking side to side
                this.lon = -orientationResult.y - (Math.PI / 2);
                this.onNewCoords({ lon: this.lon, lat: this.lat });
            }
            this.momentumX = 0;
            this.momentumY = 0;
        } else {
            // Apply any momentum
            if (!this.isUserInteracting) {
                this.lon += this.momentumX;
                this.lat += this.momentumY;
                this.momentumX *= MOMENTUM_DECAY;
                this.momentumY *= MOMENTUM_DECAY;

                if (Math.abs(this.momentumX) < MOMENTUM_STOP) {
                    this.momentumX = 0;
                }
                if (Math.abs(this.momentumY) < MOMENTUM_STOP) {
                    this.momentumY = 0;
                }
            }


            this.camera.target.x = SPHERE_RADIUS * Math.sin(this.lat) * Math.cos(this.lon);
            this.camera.target.y = SPHERE_RADIUS * Math.cos(this.lat);
            this.camera.target.z = SPHERE_RADIUS * Math.sin(this.lat) * Math.sin(this.lon);

            this.camera.lookAt(this.camera.target);

            // TODO: add an offset to orientation results, so that hitting 360 doesnt make us jump.
        }

        // Distortion
        if (this.distortion) {
            this.camera.position.copy(this.camera.target).negate();
        }

        this.renderer.render(this.scene, this.camera);
    }

    // ------
    // External control
    lookAt = (coords) => {
        this.cameraTargetAnimation = new Animation(0.5, [
            { from: this.lon, to: coords.lon },
            { from: this.lat, to: coords.lat },
        ]);

        this.controlEnabled = false;
    }

    stopViewingPlace = () => {
        this.controlEnabled = true;
        this.cameraTargetAnimation.stop();
    }

    setFullscreen = (fullscreen) => {
        this.fullscreen = fullscreen;
        this.onWindowResize();
    }

    setDeviceOrientationControlsEnabled = (enabled) => {
        this.deviceOrientationControlsEnabled = enabled;
    }
}
