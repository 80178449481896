import React, { Component } from "react";
import Panorama from "../panorama";
import PlaceSelection from "../place-selection";
import TopControls from "../top-controls";
import MinimizedControls from "../minimized-controls";
import CommentModal from "../comment-modal";
import SnapshotModal from "../snapshot-modal";
import SnapshotCommentButton from "../snapshot-comment-button";
import * as Components from "./panorama-container.style";
import { connect } from "react-redux";
import { selectFullscreen, selectIsMobile, selectOrientationAvailable } from "../../ducks/ui";
import { selectUpgradeOpenForComment } from "../../ducks/upgrade";

class PanoramaContainer extends Component {
    render() {
        // TODO: innerHeight in redux"
        const { panorama, fullscreen, innerHeight, commentsEnabled } = this.props;

        return (
            <Components.PanoramaContainer fullscreen={fullscreen} innerHeight={innerHeight}>
                <Panorama panorama={panorama} />
                <PlaceSelection panorama={panorama} />
                <TopControls panoramaId={panorama.id} />
                <MinimizedControls panorama={panorama} />
                {commentsEnabled && <SnapshotModal panoramaId={panorama.id} />}
                {commentsEnabled && <CommentModal panoramaId={panorama.id} />}
                {commentsEnabled && <SnapshotCommentButton panoramaId={panorama.id} />}
            </Components.PanoramaContainer>
        );
    }
}

const mapStateToProps = (state, props) => {
    const panoramaId = props.panorama.id;
    return {
        fullscreen: selectFullscreen(panoramaId, state),
        isMobile: selectIsMobile(state),
        orientationAvailable: selectOrientationAvailable(state),
        commentsEnabled: selectUpgradeOpenForComment(state),
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PanoramaContainer);
