import EasingFunctions from "./easing";

const defaultOptions = {
    easing: EasingFunctions.easeInOutCubic,
}

const tween = (from, to, progress) => {
    return from + ((to - from) * progress);
}

export default class Animation {
    constructor(_duration, _values, _options = {}) {
        this.duration = _duration;
        this.progress = 0;
        this.values = _values;
        this.easingFunction = _options.easing || defaultOptions.easing;
    }

    getEasedProgress() {
        return this.easingFunction(this.progress);
    }

    getValues() {
        const easedProgress = this.getEasedProgress();
        if (Array.isArray(this.values)) {
            return this.values.map(value => tween(value.from, value.to, easedProgress));
        } else {
            return tween(this.values.from, this.values.to, easedProgress);
        }
    }

    update(dt = 0.016) {
        if (this.progress >= 1) return null;
        this.progress += Math.min(1.0, (dt / this.duration));
        return this.getValues();
    }

    stop() {
        this.progress = 1;
    }
}