import React from "react";
import { connect } from "react-redux";
import {
    selectCurrentPlace,
    setCurrentPlace,
    setGeneral,
    selectGeneral,
    setGeneralComment,
} from "../../ducks/current-place";
import CommentsSection from "../comments-section";
import * as Components from "./place-selection-style";
import { selectFullscreen, setOrientationControl } from "../../ducks/ui";
import { selectSnapshotModal } from "../../ducks/comments";
import Animation from "../../modules/animation";
import { selectUpgradeOpenForComment } from "../../ducks/upgrade";

class PlaceSelection extends React.Component {
    constructor(props) {
        super(props);
        this.scrollContainer = React.createRef();
    }

    getPlaceClickHandler(place, isCurrentPlace) {
        const {
            panoramaId,
            setCurrentPlace,
            setOrientationControl,
            setGeneral,
            setGeneralComment,
        } = this.props;
        return event => {
            // Scroll to whatever is selected.
            this.startScrollAnimation(event.currentTarget.offsetLeft - 32);
            if (isCurrentPlace) {
                setCurrentPlace(panoramaId, null);
            } else {
                setOrientationControl(panoramaId, false);
                setCurrentPlace(panoramaId, place.id);
            }
            setGeneral(panoramaId, null);
            setGeneralComment(panoramaId, null);
        };
    }

    startScrollAnimation = target => {
        // TODO: make automatic frame requests an option
        const from = this.scrollContainer.current.scrollLeft;
        this.scrollAnimation = new Animation(0.4, [{ from: from, to: target }]);
        this.updateScrollAnimation();
    };

    updateScrollAnimation = () => {
        const animationResult = this.scrollAnimation.update();
        if (animationResult !== null) {
            this.scrollContainer.current.scrollLeft = animationResult;
            requestAnimationFrame(this.updateScrollAnimation);
        }
    };

    renderPlaces() {
        const { panorama, currentPlace, commentsEnabled } = this.props;
        return panorama.places.map(place => {
            const isCurrentPlace = currentPlace && place.id === currentPlace.id;
            const commentOrGoToText = commentsEnabled ? "Comment on" : "Go to";
            const jumpText = isCurrentPlace ? "You are viewing" : commentOrGoToText;
            const commentCountBubble =
                commentsEnabled && place.comments && place.comments.length ? (
                    <Components.PlaceTileCommentCount isCurrentPlace={isCurrentPlace}>
                        {place.comments.length}
                    </Components.PlaceTileCommentCount>
                ) : null;
            return (
                <Components.PlaceTile
                    key={place.id}
                    onClick={this.getPlaceClickHandler(place, isCurrentPlace)}
                    isCurrentPlace={isCurrentPlace}
                >
                    {commentCountBubble}
                    <Components.PlaceTileJumpLabel isCurrentPlace={isCurrentPlace}>
                        {jumpText}
                    </Components.PlaceTileJumpLabel>
                    <Components.PlaceTileName isCurrentPlace={isCurrentPlace}>
                        {place.name}
                    </Components.PlaceTileName>
                </Components.PlaceTile>
            );
        });
    }

    renderGeneralComments() {
        const {
            panorama,
            showingGeneral,
            currentPlace,
            setCurrentPlace,
            setGeneral,
            setGeneralComment,
        } = this.props;
        const { generalComments } = panorama;

        const isCurrentPlace = showingGeneral;
        return (
            <Components.PlaceTile
                onClick={() => {
                    if (currentPlace) {
                        setCurrentPlace(panorama.id, null);
                    }
                    setGeneral(panorama.id, !showingGeneral);
                    if (showingGeneral) {
                        setGeneralComment(panorama.id, null);
                    }
                }}
                isCurrentPlace={isCurrentPlace}
                generalTile={true}
            >
                <Components.PlaceTileCommentCount
                    generalTile={true}
                    isCurrentPlace={isCurrentPlace}
                >
                    {generalComments.length}
                </Components.PlaceTileCommentCount>
                <Components.PlaceTileJumpLabel isCurrentPlace={isCurrentPlace} />
                <Components.PlaceTileName isCurrentPlace={isCurrentPlace}>
                    General Comments
                </Components.PlaceTileName>
            </Components.PlaceTile>
        );
    }

    render() {
        const {
            panorama,
            currentPlace,
            fullscreen,
            snapshotModal,
            showingGeneral,
            commentsEnabled,
        } = this.props;
        if (!fullscreen) return null;
        const fade = Boolean(snapshotModal);

        const expanded = commentsEnabled && Boolean(currentPlace || showingGeneral);

        const commentsPlace = showingGeneral
            ? {
                  general: true,
                  comments: panorama.generalComments,
              }
            : currentPlace;

        return (
            <Components.BottomBar isExpanded={expanded} fade={fade}>
                <Components.PlaceSelection innerRef={this.scrollContainer}>
                    {this.renderPlaces()}
                    {commentsEnabled && this.renderGeneralComments()}
                </Components.PlaceSelection>
                {commentsEnabled && (
                    <CommentsSection panoramaId={panorama.id} place={commentsPlace} />
                )}
            </Components.BottomBar>
        );
    }
}

const mapStateToProps = (state, props) => {
    const panoramaId = props.panorama.id;
    return {
        panoramaId,
        currentPlace: selectCurrentPlace(panoramaId, state),
        showingGeneral: selectGeneral(panoramaId, state),
        fullscreen: selectFullscreen(panoramaId, state),
        snapshotModal: selectSnapshotModal(panoramaId, state),
        commentsEnabled: selectUpgradeOpenForComment(state),
    };
};
const mapDispatchToProps = {
    setCurrentPlace,
    setOrientationControl,
    setGeneral,
    setGeneralComment,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlaceSelection);
