import styled from "react-emotion";

export const Container = styled("div")(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: "pointer",
}));

export const FullscreenIcon = styled("button")(({ theme }) => ({
    position: "absolute",
    top: 20,
    right: 20,
    color: theme.color.white,
    border: "none",
    background: "none",
    padding: 0,
    cursor: "pointer",
}));

export const CommentButton = styled("button")(({ theme }) => ({
    position: "absolute",
    bottom: 20,
    left: 20,
    display: "flex",
    alignItems: "center",
    color: theme.color.white,
    border: "none",
    borderRadius: 3,
    background: theme.color.darkBlue,
    padding: "0 1rem",
    height: 48,
    fontWeight: theme.fontWeight.medium,
    fontSize: "1rem",
    fontFamily: theme.fontStack,
    cursor: "pointer",
}));

export const CommentButtonIcon = styled("div")(({ theme }) => ({
    marginLeft: "1rem",
    position: "relative",
    "svg": {
        width: 38,
    }
}));

export const CommentButtonCount = styled("div")(({ theme }) => ({
    position: "absolute",
    left: 19,
    top: 3,
    fontSize: "0.75rem",
    color: theme.color.darkBlue,
    transform: "translateX(-50%)",
    fontWeight: theme.fontWeight.semiBold,
}));

