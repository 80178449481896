const monthMap = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export default date => {
    const realDate = date instanceof Date ? date : new Date(date);

    const day = realDate.getDate();
    const monthNum = realDate.getMonth();
    const monthName = monthMap[monthNum];
    const year = realDate.getFullYear();

    return `${day} ${monthName} ${year}`;
};
