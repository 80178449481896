import { createSelector } from "reselect";
import { FETCH_UPGRADE_SUCCESS } from "./upgrade";
import { NEW_COMMENT_SUCCESS } from "./comments";
const SET_PANORAMA_COORDS = "tfnsw-tap/panorama/SET_PANORAMA_COORDS";

const initialState = {
    panoramasById: {},
    panoramaCoords: {},
};

// -----------------
// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case FETCH_UPGRADE_SUCCESS: {
            const { normalized } = action.payload;
            return {
                ...state,
                panoramasById: normalized.entities.panoramas,
            };
        }
        case SET_PANORAMA_COORDS: {
            const { coords, panoramaId } = action.payload;
            return {
                ...state,
                panoramaCoords: {
                    ...state.panoramaCoords,
                    [panoramaId]: coords,
                },
            };
        }
        case NEW_COMMENT_SUCCESS: {
            const { comment } = action.payload;
            // No need to insert if associated with a place, it goes in the place
            if (comment.place_id) return state;
            const panoramaId = comment.panorama_id;
            return {
                ...state,
                panoramasById: {
                    ...state.panoramasById,
                    [panoramaId]: {
                        ...state.panoramasById[panoramaId],
                        generalComments: [
                            comment.id,
                            ...state.panoramasById[panoramaId].generalComments,
                        ]
                    }
                }
            }
        }
        default:
            return state;
    }
}

// -----------------
// Action creators

export function setPanoramaCoords(panoramaId, coords) {
    return { type: SET_PANORAMA_COORDS, payload: { panoramaId, coords } };
}

// -----------------
// Selectors
export const selectPanoramas = createSelector(
    (state) => state.panoramas.panoramasById,
    x => x,
);

export const selectPanoramaCoords = (panoramaId, state) =>
    state.panoramas.panoramaCoords[panoramaId] || null;