import { ReactComponent as OrientationControl } from "./360.svg";
import { ReactComponent as Back } from "./back-button.svg";
import { ReactComponent as ChevronDown } from "./chevron-down.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as CommentBubble } from "./comment-bubble.svg";
import { ReactComponent as CommentBubbleCount } from "./comment-bubble-count.svg";
import { ReactComponent as Comment } from "./comment.svg";
import { ReactComponent as Expand } from "./expand.svg";
import { ReactComponent as RightArrow } from "./right-arrow.svg";
import { ReactComponent as Shutter } from "./shutter.svg";
import { ReactComponent as Thumb } from "./thumb.svg";
import { ReactComponent as Link } from "./link.svg";
import { ReactComponent as Crosshair } from "./crosshair.svg";

export default {
    OrientationControl,
    Back,
    ChevronDown,
    Close,
    CommentBubble,
    CommentBubbleCount,
    Comment,
    Expand,
    RightArrow,
    Shutter,
    Thumb,
    Link,
    Crosshair,
}