import React from "react";
import { connect } from "react-redux";
import Icons from "../icons";
import * as Components from "./comment-modal.style";
import {
    selectCommentModal,
    setCommentModal,
    createNewComment,
    selectSnapshotModal,
} from "../../ducks/comments";
import { selectUsername, selectEmail } from "../../ducks/user";
import commentCategories from "../../modules/comment-categories";
import Select from "react-select";
import { selectUpgradeId } from "../../ducks/upgrade";

const initialState = {
    category: null,
    username: "",
    content: "",
    email: "",
};

class CommentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...initialState };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.username !== this.props.username) {
            this.setState({
                username: this.props.username,
            });
        }
        if (prevProps.email !== this.props.email) {
            this.setState({
                email: this.props.email,
            });
        }
    }

    componentDidMount() {
        this.resetForm();
    }

    resetForm = () => {
        this.setState({
            ...initialState,
            username: this.props.username || "",
            email: this.props.email || "",
        });
    };

    handleBackClicked = () => {
        const { setCommentModal, panoramaId } = this.props;
        setCommentModal(panoramaId, null);
        this.resetForm();
    };

    handleSubmitClicked = async e => {
        const { panoramaId, createNewComment, commentModal, upgradeId } = this.props;
        e.preventDefault();

        const comment = {
            username: this.state.username,
            email: this.state.email,
            content: this.state.content,
            category: this.state.category ? this.state.category.value : undefined,
            place_id: commentModal.placeId || null,
            x: commentModal.coords ? commentModal.coords.x : null,
            y: commentModal.coords ? commentModal.coords.y : null,
            panorama_id: panoramaId,
            upgrade_id: upgradeId,
        };
        const success = await createNewComment(comment);
        if (success) {
            this.resetForm();
        }
    };

    handleUsernameChange = e => {
        this.setState({ username: e.target.value });
    };

    handleEmailChange = e => {
        this.setState({ email: e.target.value });
    };

    handleCommentChange = e => {
        this.setState({ content: e.target.value });
    };

    handleCategoryChange = category => {
        this.setState({ category });
    };

    isSubmissionDisabled() {
        if (this.props.commentModal && this.props.commentModal.updating) return true;
        if (!this.state.username) return true;
        if (!this.state.content) return true;
        return false;
    }

    renderContent() {
        const { commentModal, snapshotModal } = this.props;
        const { category, username, email, content } = this.state;

        if (!commentModal.placeId && !commentModal.coords) {
            return null;
        }

        const categoryOptions = commentCategories.map(cat => ({
            value: cat,
            label: cat,
        }));

        const submitDisabled = this.isSubmissionDisabled();
        return (
            <Components.Content>
                <Components.BackButtonRow>
                    <button onClick={this.handleBackClicked}>
                        <Icons.RightArrow />
                        Back to {snapshotModal ? "comment mode" : "360 view"}
                    </button>
                </Components.BackButtonRow>
                <Components.CommentForm>
                    <Components.CommentFormRow>
                        <Components.CommentFieldLabel htmlFor="commentFormName">
                            Name
                        </Components.CommentFieldLabel>
                        <Components.CommentInput
                            placeholder="Enter your name here"
                            id="commentFormName"
                            value={username}
                            onChange={this.handleUsernameChange}
                            autoComplete="name"
                        />
                    </Components.CommentFormRow>
                    <Components.CommentFormRow>
                        <Components.CommentFieldLabel htmlFor="commentFormEmail">
                            Email (optional)
                        </Components.CommentFieldLabel>
                        <Components.CommentInput
                            placeholder="Enter your email here (optional)"
                            id="commentFormEmail"
                            value={email || ""}
                            onChange={this.handleEmailChange}
                            type="email"
                            autoComplete="email"
                        />
                    </Components.CommentFormRow>
                    <Components.CommentFormRow>
                        <Components.CommentFieldLabel htmlFor="commentFormComment">
                            Comment
                        </Components.CommentFieldLabel>
                        <Components.CommentTextarea
                            placeholder="Enter feedback here"
                            id="commentFormComment"
                            value={content}
                            onChange={this.handleCommentChange}
                        />
                    </Components.CommentFormRow>
                    <Components.CommentFormRow>
                        <Components.CommentFieldLabel htmlFor="commentFormCategory">
                            This comment relates to
                        </Components.CommentFieldLabel>
                        <Select
                            id="commentFormCategory"
                            value={category}
                            onChange={this.handleCategoryChange}
                            options={categoryOptions}
                            styles={Components.CategoryDropdown}
                            placeholder="Select category"
                        />
                    </Components.CommentFormRow>
                    {commentModal.error && (
                        <Components.Error>{commentModal.error}</Components.Error>
                    )}
                    <Components.CommentSubmitButton
                        onClick={this.handleSubmitClicked}
                        disabled={submitDisabled}
                    >
                        Submit
                    </Components.CommentSubmitButton>
                </Components.CommentForm>
                <Components.CommentLoaderOverlay visible={commentModal.submitting}>
                    SUBMITTING
                </Components.CommentLoaderOverlay>
            </Components.Content>
        );
    }

    render() {
        const { commentModal } = this.props;
        const visible = Boolean(commentModal && (commentModal.placeId || commentModal.coords));
        const content = visible ? this.renderContent() : null;

        return <Components.CommentsModal visible={visible}>{content}</Components.CommentsModal>;
    }
}

const mapStateToProps = (state, props) => {
    const { panoramaId } = props;
    return {
        commentModal: selectCommentModal(panoramaId, state),
        snapshotModal: selectSnapshotModal(panoramaId, state),
        username: selectUsername(state),
        email: selectEmail(state),
        upgradeId: selectUpgradeId(state),
    };
};
const mapDispatchToProps = {
    setCommentModal,
    createNewComment,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentModal);
