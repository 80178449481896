import { createSelector } from "reselect";
import { FETCH_UPGRADE_SUCCESS } from "./upgrade";
import { NEW_COMMENT_SUCCESS } from "./comments";

const initialState = {
    placesById: { },
};


// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case FETCH_UPGRADE_SUCCESS: {
            const { normalized } = action.payload;
            return {
                ...state,
                placesById: normalized.entities.places,
            };
        }
        case NEW_COMMENT_SUCCESS: {
            const { comment } = action.payload;
            // No need to insert if not associated with a place, it goes in the pano
            if (!comment.place_id) return state;
            const placeClone = { ...state.placesById[comment.place_id] };
            placeClone.comments = [comment.id].concat(placeClone.comments);
            return {
                ...state,
                placesById: {
                    ...state.placesById,
                    [comment.place_id]: placeClone,
                }
            }
        }
        default:
            return state;
    }
}

// -----------------
// Action creators

// -----------------
// Thunks

// -----------------
// Selectors
export const selectPlaces = createSelector(
    (state) => state.places.placesById,
    x => x,
);