import styled from "react-emotion";

export const CommentsSection = styled("div")(({ theme }) => ({
    height: "calc(40vh - 140px)",
    flexGrow: 1,
    background: "rgba(255,255,255,0.85)",
    display: "flex",
    flexDirection: "column",
    padding: "1rem 0.75rem 1rem 1.25rem",
}));

export const CommentScroll = styled("div")(({ theme }) => ({
    width: "100%",
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    paddingRight: "0.5rem",
    height: "100%",
}));


export const Comment = styled("div")(({ theme }) => ({
    width: "100%",
    margin: "1rem 0",
    boxSizing: "border-box",
    paddingRight: "0.5rem",
    "&:last-child": {
        marginBottom: "8rem",
    }
}));

export const CommentMetaRow = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

export const CommentUserInfo = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
}));

export const CommentUsername = styled("h4")(({ theme }) => ({
    fontSize: "1rem",
    fontWeight: theme.fontWeight.bold,
    margin: 0,
    marginRight: "0.5rem",
}));

export const CommentTimestamp = styled("span")(({ theme }) => ({
    color: theme.color.midGrey,
    fontSize: "0.75rem",
    fontWeight: theme.fontWeight.light,
    transform: "translateY(1px)",
}));

export const CommentUpvotes = styled("div")(
    ({ theme }) => ({
        display: "flex",
        alignItems: "center",
        color: theme.color.midGrey,
        transition: "color 0.2s, background 0.2s",
        padding: "0.125rem 0.25rem",
        borderRadius: 3,
        svg: {
            width: 16,
            height: 20,
            marginLeft: 4,
            transition: "transform 0.4s",
            transform: "translateY(-1px)",
        },
        ":hover": {
            "svg": {
                transform: "translateY(-1px) scale(1.25)",
            },
            background: "#E0E0E0",
            color: theme.color.primary,
        }
    }),
    ({ theme, userVoted }) =>
        userVoted
            ? {
                    color: theme.color.highlight,
                    ":hover": {
                        background: "#EEE",
                        color: theme.color.highlight,
                    }
              }
            : {}
);

export const CommentContent = styled("p")(({ theme }) => ({
    fontSize: "0.875rem",
    lineHeight: "1.3125rem",
    fontWeight: theme.fontWeight.light,
    margin: "0.5rem 0",
}));

export const CommentLocation = styled("a")(({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: theme.fontWeight.bold,
    color: theme.color.darkBlue,
    margin: "0.5rem 0",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover": {
        textDecoration: "underline",
    },
    "svg": {
        marginTop: 1,
        marginRight: "0.25rem",
    }
}));

export const CommentsPlaceholder = styled("p")(({ theme }) => ({}));

export const CommentFadeout = styled("div")(({ theme, visible }) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "10vh",
    minHeight: 120,
    background: "linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0.76) 59.57%, rgba(255,255,255,0) 100%)",
    transition: "opacity 0.2s",
    opacity: visible ? 1.0 : 0.0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingBottom: 20,
    pointerEvents: "none",
}));

export const CommentButton = styled("button")(({ theme, visible }) => ({
    pointerEvents: visible ? "all" : "none",
    background: theme.color.darkBlue,
    border: "none",
    color: theme.color.white,
    borderRadius: 3,
    padding: "0.75rem 0",
    width: "calc(100% - 2rem)",
    fontFamily: theme.fontStack,
    fontSize: "1rem",
    fontWeight: theme.fontWeight.medium,
    textAlign: "center",
}));
