const initialState = {};

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        default:
            return state;
    }
}

// -----------------
// Action creators

// Not required - in production environments this is embedded and in initial state.

// -----------------
// Selectors
export const selectEnv = state => state.app.env;
