import React from "react";
import { connect } from "react-redux";
import Icons from "../icons";
import * as Components from "./minimized-controls.style";
import { setFullscreen, selectFullscreen } from "../../ducks/ui";
import { selectUpgradeOpenForComment } from "../../ducks/upgrade";

class MinimizedControls extends React.Component {
    handleFullscreenClick = () => {
        const { panorama, setFullscreen } = this.props;
        setFullscreen(panorama.id, true);
    };

    render() {
        const { panorama, fullscreen, commentsEnabled } = this.props;
        if (fullscreen) return null;

        const commentCount = panorama.places.reduce(
            (count, place) => count + place.comments.length,
            panorama.generalComments.length
        );

        return (
            <Components.Container onClick={this.handleFullscreenClick}>
                <Components.FullscreenIcon>
                    <Icons.Expand />
                </Components.FullscreenIcon>
                {commentsEnabled && (
                    <Components.CommentButton>
                        Leave a comment
                        <Components.CommentButtonIcon>
                            <Icons.CommentBubbleCount />
                            <Components.CommentButtonCount>
                                {commentCount >= 1000 ? "1k+" : commentCount}
                            </Components.CommentButtonCount>
                        </Components.CommentButtonIcon>
                    </Components.CommentButton>
                )}
            </Components.Container>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { panorama } = props;
    return {
        fullscreen: selectFullscreen(panorama.id, state),
        commentsEnabled: selectUpgradeOpenForComment(state),
    };
};
const mapDispatchToProps = {
    setFullscreen,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MinimizedControls);
