import { createSelector } from "reselect";
import { FETCH_UPGRADE_SUCCESS } from "./upgrade";

const initialState = {
    componentsById: { },
};

// -----------------
// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case FETCH_UPGRADE_SUCCESS: {
            const { normalized } = action.payload;
            return {
                ...state,
                componentsById: normalized.entities.components,
            };
        }
        default:
            return state;
    }
}

// -----------------
// Selectors
export const selectComponents = createSelector(
    (state) => state.components.componentsById,
    x => x,
);