import styled from "react-emotion";

export const StationMap = styled("div")(
    ({ theme }) => ({
        position: "relative",
    }),
);

export const StationMapImage = styled("img")(
    ({ theme }) => ({
        width: "100%",
    }),
);

export const StationMapMarker = styled("button")(
    ({ theme, left, top }) => ({
        fontFamily: theme.fontStack,
        width: 30,
        height: 30,
        left: left,
        top: top,
        transform: "translate(-50%, -50%)",
        position: "absolute",
        background: theme.color.lightBlue,
        border: "3px solid white",
        borderRadius: "50%",
        color: theme.color.white,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        fontSize: "0.75rem",
        marginRight: "0.5rem",
        cursor: "pointer",
        transition: "transform 0.2s",
        padding: 0,
        lineHeight: "1.5rem",
        "&:hover": {
            transform: "translate(-50%, -50%) scale(1.06)",
        }
    }),
);