import { findDOMNode } from "react-dom";

const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

export default function scrollToReactComponent(component, offset = 50) {
    const htmlElement = component && component.props ? findDOMNode(component) : component;
    if (!htmlElement) {
        console.error(`Could not find element`);
        return;
    }
    const top = htmlElement.offsetTop - offset
    if (isSmoothScrollSupported) {
        window.scroll({
            top: top,
            left: 0,
            behavior: "smooth",
        });
    } else {
        window.scroll(0, top);
    }
}
