import styled from "react-emotion";

export const AppOuter = styled("div")(
    ({ theme }) => ({
        fontFamily: theme.fontStack,
    }),
);

export const ComponentRank = styled("div")(
    ({ theme }) => ({
        width: 30,
        height: 30,
        background: theme.color.lightBlue,
        border: "3px solid white",
        borderRadius: "50%",
        color: theme.color.white,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        fontSize: "0.75rem",
        marginRight: "0.5rem",
    }),
);

