import styled, { keyframes } from "react-emotion";

const bounce = keyframes({
  "0%": {
    transform: "translateX(-50%) translateY(-3px)"
  },
  "50%": {
    transform: "translateX(-50%) translateY(0px)"
  },
  "100%": {
    transform: "translateX(-50%) translateY(3px)"
  },
})

const pulse = keyframes({
    "0%": {
      transform: "scale(0.96)"
    },
    "100%": {
      transform: "scale(1.04)"
    },
  })
  

export const SnapshotModal = styled("div")(
    ({ theme }) => ({
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: "rgba(0,0,0, 0.2)",
        display: "flex",
        flexDirection: "column",
        opacity: 0,
        transition: "opacity 0.2s",
        pointerEvents: "none",
    }),
    ({ visible }) =>
        visible
            ? {
                  opacity: 1,
              }
            : {}
);

export const ExitButton = styled("button")(({ theme }) => ({
    pointerEvents: "all",
    position: "fixed",
    top: "2rem",
    left: "50%",
    transform: "translateX(-50%)",
    background: theme.color.white,
    color: theme.color.darkBlue,
    border: "2px solid " + theme.color.darkBlue,
    borderRadius: 3,
    padding: "0.75rem",
    fontFamily: theme.fontStack,
    fontSize: "1rem",
    fontWeight: theme.fontWeight.medium,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
    svg: {
        marginLeft: 10,
        transform: "translateY(-1px)",
        width: 14,
        height: 14,
    },
}));

export const ShutterTooltip = styled("div")(({ theme }) => ({
    position: "absolute",
    background: "rgba(255,255,255,0.75)",
    bottom: 120,
    left: "50%",
    width: 280,
    transform: "translateX(-50%)",
    pointerEvents: "none",
    padding: "0.5rem 1.5rem",
    textAlign: "center",
    color: "#000",
    fontSize: "1rem",
    fontWeight: theme.fontWeight.light,
    lineHeight: "1.5rem",
    borderRadius: 5,
    boxSizing: "border-box",
    animation: `${bounce} 5s ease-in-out infinite alternate`,
    "p": {
        margin: 0,
        transform: "translateY(2px)",
    }
}));

export const ShutterTooltipTriangle = styled("div")(({ theme }) => ({
    content: '""',
    width: 0, 
    height: 0, 
    borderLeft: "10px solid transparent",  /* left arrow slant */
    borderRight: "10px solid transparent", /* right arrow slant */
    borderTop: "6px solid rgba(255,255,255,0.75)", /* bottom, add background color here */
    fontSize: 0,
    lineHeight: 0,
    position: "absolute",
    bottom: -6,
    left: "50%",
    transform: "translateX(-50%)",
}));


export const BorderHighlight = styled("div")(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: "5px solid " + theme.color.lightBlue,
    pointerEvents: "none",
}));

export const ShutterWrapper = styled("button")(({ theme, visible }) => ({
    pointerEvents: visible ? "all" : "none",
    background: "none",
    border: "none",
    position: "absolute",
    left: "50%",
    bottom: 20,
    transform: "translateX(-50%)",
    svg: {
        transition: "transform 0.2s",
        animation: `${pulse} 2s ease-in-out infinite alternate`,
        ":hover": {
            transform: "scale(1.2)",
        },
    },
}));
