export const getUrl = (path, apiUrl) => {
    // load REACT_APP_TAP_BACKEND_URL_DEV from .env.local if created when developing locally.
    if (process.env.REACT_APP_TAP_BACKEND_URL_DEV) {
      return `${process.env.REACT_APP_TAP_BACKEND_URL_DEV}${path}`;
    }
    if (window.location.hostname === "localhost") {
        return `http://localhost:3001${path}`;
    }
    return apiUrl ? `${apiUrl}${path}` : `http://localhost:3001${path}`;
};

function FetchException(obj) {
    Object.keys(obj).forEach(key => {
        this[key] = obj[key];
    })
 }

const makeRequest = async (method, path, apiUrl, body) => {
    const url = getUrl(path, apiUrl);

    const isForm = body instanceof FormData

    const bodyToUse = body
        ? (
            isForm
                ? body
                : JSON.stringify(body)
        )
        : undefined;

    const headers = isForm
        ? undefined
        : new Headers({
            "Content-Type": "application/json",
        });
    const res = await fetch(url, {
        method,
        credentials: "include",
        body: bodyToUse,
        headers: headers,
    });

    if (res.ok) {
        return res;
    } else {
        const body = await res.json();
        if (body) {
            throw new FetchException(body);
        } else {
            throw new Error(`${res.statusCode} ${res.statusText}: ${body}`);
        }
    }
};

export default makeRequest;
