import { createSelector } from "reselect";
import { denormalizePlace } from "../modules/upgrade-normalizr";
import { selectComments } from "./comments";
import { selectPlaces } from "./places";

export const SET_CURRENT_PLACE = "tfnsw-tap/current-place/SET_CURRENT_PLACE";
export const SET_GENERAL = "tfnsw-tap/current-place/SET_GENERAL";
export const SET_GENERAL_COMMENT = "tfnsw-tap/current-place/SET_GENERAL_COMMENT";

const initialState = {
    currentPlaceByPanorama: { },
    generalByPanorama: { },
    generalCommentByPanorama: { },
};

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_CURRENT_PLACE: {
            return {
                ...state,
                currentPlaceByPanorama: {
                    ...state.currentPlaceByPanorama,
                    [action.payload.panoramaId]: action.payload.place,
                }
            };
        }
        case SET_GENERAL: {
            return {
                ...state,
                generalByPanorama: {
                    ...state.generalByPanorama,
                    [action.payload.panoramaId]: action.payload.general,
                }
            };
        }
        case SET_GENERAL_COMMENT: {
            return {
                ...state,
                generalCommentByPanorama: {
                    ...state.generalCommentByPanorama,
                    [action.payload.panoramaId]: action.payload.comment,
                }
            };
        }
        default:
            return state;
    }
}

// -----------------
// Action creators

export function setCurrentPlaceAction(panoramaId, place) {
    return { type: SET_CURRENT_PLACE, payload: {
        panoramaId,
        place,
    } };
}

export function setGeneral(panoramaId, general) {
    return { type: SET_GENERAL, payload: {
        panoramaId,
        general,
    } };
}

export function setGeneralComment(panoramaId, comment) {
    return { type: SET_GENERAL_COMMENT, payload: {
        panoramaId,
        comment,
    } };
}
// -----------------
// Thunks

export function setCurrentPlace(panoramaId, place) {
    return async (dispatch) => {
        dispatch(setCurrentPlaceAction(panoramaId, place));
    };
}

// -----------------
// Selectors

// TODO: move this to places duck?
export const selectCurrentPlaceId = (panoramaId, state) => state.currentPlace.currentPlaceByPanorama[panoramaId];
export const selectGeneral = (panoramaId, state) => state.currentPlace.generalByPanorama[panoramaId];
export const selectGeneralComment = (panoramaId, state) => state.currentPlace.generalCommentByPanorama[panoramaId];

export const selectPlaceSelected = createSelector(
    selectCurrentPlaceId,
    selectGeneral,
    (placeId, general) => {
        return placeId || general;
    }
)

export const selectCurrentPlace = createSelector(
    selectCurrentPlaceId,
    (panoramaId, state) => selectComments(state),
    (panoramaId, state) => selectPlaces(state),
    (currentPlaceId, comments, places) => {
        return denormalizePlace(currentPlaceId, {
            comments,
            places,
        })
    }
);