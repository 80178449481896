import React, { Component } from "react";
import * as Components from "./App.style";
import { connect } from "react-redux";
import { fetchUser } from "./ducks/user";
import { fetchUpgrade, selectUpgrade } from "./ducks/upgrade";
import { setIsMobile, selectIsMobile, setOrientationAvailable, selectOrientationAvailable } from "./ducks/ui";
import checkIfMobile from "./modules/check-if-mobile";
import PanoramaContainer from "./components/panorama-container";
import StationMap from "./components/station-map";
import scrollToComponent from "./modules/scroll-to-component";
import { selectEnv } from "./ducks/app";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            innerHeight: window.innerHeight,
        }
        this.componentHeaders = {};
    }

    componentDidMount = () => {
        this.props.fetchUser();
        this.props.fetchUpgrade(this.props.upgradeId);
        window.addEventListener("resize", this.handleWindowResize);
        window.addEventListener("deviceorientation", this.handleDeviceOrientationChangeEvent, false);
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
        window.removeEventListener("deviceorientation", this.handleDeviceOrientationChangeEvent);
    }

    handleWindowResize = () => {
        const { setIsMobile, isMobile } = this.props;
        const isNowMobile = checkIfMobile();
        if (isMobile !== isNowMobile) {
            setIsMobile(isNowMobile);
        }

        this.setState({
            innerHeight: window.innerHeight,
        })
    };

    handleDeviceOrientationChangeEvent = (event) => {
        const { orientationAvailable, setOrientationAvailable } = this.props;
        if (event) {
            if (!orientationAvailable) {
                setOrientationAvailable(true);
            }
            window.removeEventListener("deviceorientation", this.handleDeviceOrientationChangeEvent, false);
        }
    };

    renderPanorama(panorama) {
        return (
            <div key={panorama.id}>
                <h5>{panorama.name}</h5>
                <PanoramaContainer panorama={panorama} innerHeight={this.state.innerHeight} />
            </div>
        )
    }

    renderComponent(component, index) {
        return (
            <div key={component.id} ref={r => this.componentHeaders[component.id] = r}>
                <h4>
                    <Components.ComponentRank>{index + 1}</Components.ComponentRank>
                    {component.type}
                </h4>
                {component.panoramas.map(pano => this.renderPanorama(pano))}
            </div>
        )
    }

    handleStationMapClick = (component) => {
        scrollToComponent(this.componentHeaders[component.id])
    }

    renderUpgrade() {
        const { upgrade, env } = this.props;
        if (!upgrade) return null;

        return (
            <div>
                <StationMap upgrade={upgrade} onMarkerClick={this.handleStationMapClick} env={env} />
                {upgrade.components.map((component, index) => this.renderComponent(component, index))}
            </div>
        )
    }

    render() {
        return (
            <Components.AppOuter>
                {this.renderUpgrade()}
            </Components.AppOuter>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        isMobile: selectIsMobile(state),
        orientationAvailable: selectOrientationAvailable(state),
        upgrade: selectUpgrade(state),
        env: selectEnv(state),
    };
};

const mapDispatchToProps = {
    fetchUser,
    setIsMobile,
    setOrientationAvailable,
    fetchUpgrade,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
