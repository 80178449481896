const mobileBreakpoint = 700;
module.exports = {
    color: {
        /* Text Colours */
        highlight: "#0368B5",
        lightBlue: "#4AC3F0",
        darkBlue: "#004375",
        primary: "#222222",
        white: "#ffffff",
        midGrey: "#666666",
    },
    fontWeight: {
        light: 300,
        normal: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    fontStack: `"Gotham SSm A", "Gotham", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
    mobileBreakpoint,
    mobile: `@media (max-width: ${mobileBreakpoint}px)`,
};
