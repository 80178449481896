import styled from "react-emotion";

export const CommentsModal = styled("div")(
    ({ theme }) => ({
        position: "fixed",
        top: "100vh",
        right: 0,
        bottom: 0,
        left: 0,
        background: theme.color.white,
        display: "flex",
        flexDirection: "column",
        transition: "top 0.2s",
    }),
    ({ visible }) =>
        visible
            ? {
                  top: 0,
              }
            : {}
);

export const Content = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    flexGrow: 1,
    height: "100%",
}));

export const BackButtonRow = styled("div")(({ theme }) => ({
    display: "flex",
    "button": {
        fontFamily: theme.fontStack,
        fontSize: "1rem",
        fontWeight: theme.fontWeight.medium,
        background: "none",
        border: "none",
    },
    "svg": {
        width: 9,
        height: 17,
        transform: "rotate(180deg) translateY(-2px)",
        marginRight: 10,
    }
}));


export const CommentForm = styled("form")(({ theme }) => ({
    position: "relative",
    flexGrow: 1,
}));

export const CommentFormRow = styled("div")(({ theme }) => ({
    margin: "1rem 0",
    display: "flex",
    flexDirection: "column",
}));

export const CommentFieldLabel = styled("label")(({ theme }) => ({
    
}));

export const CommentInput = styled("input")(({ theme }) => ({
    fontFamily: theme.fontStack,
    border: "1px solid #A6A6A6",
    padding: "0.75rem 1rem",
    fontSize: "1rem",
    fontWeight: theme.fontWeight.light,
    marginTop: "0.5rem",
}));

export const CommentTextarea = styled("textarea")(({ theme }) => ({
    fontFamily: theme.fontStack,
    border: "1px solid #A6A6A6",
    padding: "0.75rem 1rem",
    fontSize: "1rem",
    fontWeight: theme.fontWeight.light,
    marginTop: "0.5rem",
}));

export const Error = styled("p")(({ theme }) => ({
    position: "absolute",
    bottom: 48,
    color: "#EE220c",
    borderRadius: 3,
    padding: "0.75rem 0",
    width: "100%",
    fontFamily: theme.fontStack,
    fontSize: "1rem",
    fontWeight: theme.fontWeight.medium,
    textAlign: "center",
}));

export const CommentSubmitButton = styled("button")(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    background: theme.color.darkBlue,
    color: theme.color.white,
    borderRadius: 3,
    padding: "0.75rem 0",
    width: "100%",
    fontFamily: theme.fontStack,
    fontSize: "1rem",
    fontWeight: theme.fontWeight.medium,
    textAlign: "center",
    transition: "color 0.2s, background 0.2s",
    ":disabled": {
        background: "#A6A6A6",
        color: theme.color.primary,
        cursor: "not-allowed",
    }
}));

export const CommentLoaderOverlay = styled("div")(({ theme, visible }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "rgba(255,255,255, 0.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    opacity: visible ? 1.0 : 0.0,
    transition: "opacity 0.2s",
}));

export const CategoryDropdown = {
    control: (base, state) => ({
        ...base,
        border: "1px solid #A6A6A6",
        borderRadius: 0,
        fontSize: "1rem",
        fontWeight: 300,
        marginTop: "0.5rem",
        height: 45,
        ":hover": {
            border: "1px solid #999",
        },
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    menu: (base, state) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        boxShadow: "none",
        border: "1px solid #A6A6A6",
    }),
    option: (base, state) => ({
        ...base,
        padding: 10,
        fontSize: "1rem",
        color: state.isSelected ? "#FFFFFF" : "#222222",
        background: state.isSelected ? "#004375" : "none",
        ":hover": {
            background: state.isSelected ? "#004375" : "rgba(0, 67, 117, 0.75)",
            color: "#FFFFFF",
        }
    }),
}