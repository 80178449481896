import styled from "react-emotion";

export const PanoramaContainer = styled("div")(
    ({ theme }) => ({
        width: "100%",
        height: "35vh",
        position: "relative",
    }),
    ({ fullscreen, innerHeight }) =>
        fullscreen
            ? {
                  position: "fixed",
                  height: innerHeight,
                  top: 0,
                  left: 0,
                  right: 0,
                  maxWidth: null,
                  zIndex: 99999,
              }
            : {}
);

