import styled from "react-emotion";

export const SnapshotCommentButton = styled("button")(
    ({ theme, visible, expanded, ie11 }) => ({
        position: "relative",
        background: theme.color.darkBlue,
        color: theme.color.white,
        border: "none",
        padding: "0.75rem",
        paddingRight: "3rem",
        fontFamily: theme.fontStack,
        fontSize: "1rem",
        fontWeight: theme.fontWeight.medium,
        textAlign: "center",
        display: "flex",
        alignItems: "flex-end",
        transition: "color 0.2s, background 0.2s",
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? "all" : "none",
        whiteSpace: "nowrap",
        margin: 0,
        height: 46,
        cursor: "pointer",
        svg: {
            position: "absolute",
            bottom: "50%",
            right: 11,
            transform: "translateY(50%) translateY(-1px)",
            maxWidth: 24,
            maxHeight: 22,
        },
        span: {
            opacity: expanded ? 1.0 : 0,
            transition: "opacity 0.4s",
            lineHeight: "1.3rem",
        }
    }),
);

export const SnapshotExpandContainer = styled("div")(
    ({ theme, visible }) => ({
        position: "fixed",
        bottom: 140 + 16,
        right: "1rem",
        overflow: "hidden",
        pointerEvents: "none",
        maxWidth: 48,
        transition: "max-width 0.4s",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        borderRadius: 4,
    }),
    ({ expanded }) =>
        expanded
            ? {
                  maxWidth: 280,
                  color: "red",
              }
            : {}
);
