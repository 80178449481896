import makeRequest from "../modules/make-request";
import { NEW_COMMENT_REQUEST } from "./comments";
import { selectEnv } from "./app";

const initialState = {
    userId: null,
    username: null,
    email: null,
    fetchingUser: false,
    creatingUser: false,
    error: null,
};
const USER_REQUEST = "tfnsw-tap/user/USER_REQUEST";
const USER_SUCCESS = "tfnsw-tap/user/USER_SUCCESS";
const USER_ERROR = "tfnsw-tap/user/USER_ERROR";
// const USER_CREATE_REQUEST = "tfnsw-tap/user/USER_CREATE_REQUEST";
// const USER_CREATE_SUCCESS = "tfnsw-tap/user/USER_CREATE_SUCCESS";
// const USER_CREATE_ERROR = "tfnsw-tap/user/USER_CREATE_ERROR";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case USER_REQUEST: {
            return { ...state, fetchingUser: true };
        }
        case USER_SUCCESS: {
            const { username, email, id } = action.payload;
            return { ...state, fetchingUser: false, username, email, userId: id };
        }
        case USER_ERROR: {
            return { ...state, error: action.error };
        }
        // case USER_CREATE_REQUEST: {
        //     return { ...state, creatingUser: true };
        // }
        // case USER_CREATE_SUCCESS: {
        //     const { username, id } = action.payload;
        //     return { ...state, creatingUser: false, username, userId: id };
        // }
        // When new comments are made, overwrite existing username with most up to date.
        case NEW_COMMENT_REQUEST: {
            const { username, email } = action.payload.comment;
            return {
                ...state,
                username,
                email,
            };
        }
        default:
            return state;
    }
}

// -----------------
// Action creators
const userRequest = method => ({ type: USER_REQUEST, method });
const userSuccess = payload => ({ type: USER_SUCCESS, payload });
const userError = error => ({ type: USER_ERROR, error });
// const userCreateRequest = method => ({ type: USER_CREATE_REQUEST, method });
// const userCreateSuccess = payload => ({ type: USER_CREATE_SUCCESS, payload });
// const userCreateError = error => ({ type: USER_CREATE_ERROR, error });

// -----------------
// Thunks
export const fetchUser = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const { user } = state;
        const env = selectEnv(state);
        if (user.fetchingUser) return;
        dispatch(userRequest());
        try {
            const res = await makeRequest("GET", "/users/", env);
            const user = await res.json();
            dispatch(userSuccess(user));
        } catch (error) {
            console.log(error);
            dispatch(userError("Error fetching user"));
            // we don't care it's probably non-existing user
        }
    };
};

// -----------------
// Selectors
export const selectUserId = state => state.user.userId || null;
export const selectUsername = state => state.user.username || null;
export const selectEmail = state => state.user.email || null;
