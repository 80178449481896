export default (place, isMobile) => {
    const { center_x, center_y } = place;

    const offset_x = center_x;
    const offset_y = center_y + (isMobile ? Math.PI * 0.015 : 0);
    
    return {
        lon: (offset_x * Math.PI * 2),
        lat: (offset_y * Math.PI * 2) - (Math.PI / 2),
    }
}

export const normalizePanoramaCoords = (coords) => {
    const { lon, lat } = coords;
    const x = (lon / (Math.PI * 2));
    const y = (lat + (Math.PI / 2)) / (Math.PI * 2);
    return { x, y };
}