import styled, { keyframes } from "react-emotion";

const bounce = keyframes({
    "0%": {
      transform: "translateX(-2px)"
    },
    "50%": {
      transform: "translateX(0px)"
    },
    "100%": {
      transform: "translateX(2px)"
    },
  })

export const TopControlsContainer = styled("div")(({ theme, fade }) => ({
    width: "100%",
    position: "absolute",
    top: fade ? -60 : 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    boxSizing: "border-box",
    transition: "top 0.2s",    
}));

export const CloseIcon = styled("button")(({ theme }) => ({
    position: "absolute",
    right: "1rem",
    color: theme.color.white,
    cursor: "pointer",
    border: "none",
    background: "none",
    padding: 0,
    width: 20,
    height: 20,
    "svg": {
        width: 20,
        height: 20,
    }
}));

export const OrientationControlIcon = styled("button")(
    ({ theme }) => ({
        position: "absolute",
        left: "1rem",
        color: theme.color.white,
        background: "transparent",
        cursor: "pointer",
        transition: "opacity 0.2s",
        border: "none",
    }),
    ({ theme, showOrientation, orientationControl }) =>
        showOrientation
            ? {
                  opacity: orientationControl ? 1 : 0.75,
              }
            : {
                  pointerEvents: "none",
                  opacity: 0,
              },
);

export const OrientationControlTooltip = styled("div")(({ theme, showTooltip }) => ({
    position: "absolute",
    background: "rgba(255,255,255,0.75)",
    top: "1rem",
    left: 106,
    maxWidth: 182,
    pointerEvents: "none",
    padding: "0.25rem 0.5rem",
    textAlign: "left",
    fontSize: "0.875rem",
    fontWeight: theme.fontWeight.light,
    lineHeight: "1.125rem",
    borderRadius: 3,
    boxSizing: "border-box",
    animation: `${bounce} 5s ease-in-out infinite alternate`,
    transition: "opacity 0.2s",
    opacity: showTooltip ? 1 : 0,
}));

export const OrientationControlTooltipTriangle = styled("div")(({ theme }) => ({
    content: '""',
    width: 0, 
    height: 0, 
    borderBottom: "5px solid transparent",
    borderTop: "5px solid transparent",
    borderRight: "5px solid rgba(255,255,255,0.75)",
    fontSize: 0,
    lineHeight: 0,
    position: "absolute",
    left: -5,
    top: "50%",
    transform: "translateY(-50%)",
}));

export const CommentPrivacyNotification = styled("div")(({ theme, showNotification }) => ({
  position: "absolute",
  background: "rgba(255,255,255,0.75)",
  top: "8rem",
  left: "50%",
  padding: "1rem 2rem",
  fontSize: "0.875rem",
  fontWeight: theme.fontWeight.light,
  lineHeight: "1.125rem",
  textAlign: "center",
  borderRadius: 3,
  boxSizing: "border-box",
  transition: "opacity 0.2s, transform 0.2s",
  opacity: showNotification ? 1 : 0,
  transform: showNotification ? "translate(-50%, -1rem)" : "translate(-50%, 0)",
  "a": {
    outline: "none",
  }
}));