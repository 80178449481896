import { combineReducers } from "redux";
import panoramas from "./panoramas";
import comments from "./comments";
import places from "./places";
import currentPlace from "./current-place";
import user from "./user";
import ui from "./ui";
import components from "./components";
import upgrade from "./upgrade";
import app from "./app";

export default combineReducers({
    panoramas,
    currentPlace,
    user,
    ui,
    comments,
    places,
    components,
    upgrade,
    app,
});
