import React from "react";
import { connect } from "react-redux";
import Icons from "../icons";
import * as Components from "./snapshot-comment-button.style";
import { setSnapshotModal, selectSnapshotModal } from "../../ducks/comments";
import { selectFullscreen } from "../../ducks/ui";
import { selectPlaceSelected } from "../../ducks/current-place";

const EXPAND_TIMEOUT = 10000;

class SnapshotCommentButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        }
    }

    handleClick = () => {
        const { panoramaId, setSnapshotModal } = this.props;
        // Always expanded now.
        const expanded = true; //ie11 || this.state.expanded;
        if (expanded) {
            setSnapshotModal(panoramaId, { active: true });
            this.setState({ expanded: false });
        } else {
            this.setState({ expanded: true });
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.setState({ expanded: false });
            }, EXPAND_TIMEOUT)
        }
    };

    render() {
        const { placeSelected, fullscreen, snapshotModal } = this.props;
        const visible = !placeSelected && fullscreen && !snapshotModal;
        // We are always expanded now.
        const expanded = true; // ie11 || this.state.expanded;
        return (
            <Components.SnapshotExpandContainer expanded={expanded}>
                <Components.SnapshotCommentButton visible={visible} onClick={this.handleClick} expanded={expanded}>
                    <span>Leave a general comment</span>
                    <Icons.CommentBubble />
                </Components.SnapshotCommentButton>
            </Components.SnapshotExpandContainer>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { panoramaId }  = props;
    return {
        fullscreen: selectFullscreen(panoramaId, state),
        placeSelected: selectPlaceSelected(panoramaId, state),
        snapshotModal: selectSnapshotModal(panoramaId, state),
    };
};
const mapDispatchToProps = {
    setSnapshotModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SnapshotCommentButton);
