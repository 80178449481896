import styled from "react-emotion";

export const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
})

export const Canvas = styled("canvas")(({ theme }) => ({
    width: "100%",
    flexGrow: 1,
}));
