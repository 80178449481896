import React from "react";
import { connect } from "react-redux";
import * as Components from "./top-controls.style";
import Icons from "../icons";
import {
    selectFullscreen,
    setFullscreen,
    setOrientationControl,
    selectOrientationControl,
    selectOrientationAvailable,
} from "../../ducks/ui";
import { selectPlaceSelected } from "../../ducks/current-place";
import { selectSnapshotModal, selectThankYouMessageState, setThankYouMessageState } from "../../ducks/comments";

class TopControls extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipViewed: false,
        };
    }

    componentDidUpdate(oldProps) {
        if (this.props.showThankYou && !oldProps.showThankYou) {
            this.startThankYouMessageTimeout();
        }
        if (!this.props.showThankYou && this.thankYouMessageTimeout) {
            this.clearThankYouMessageTimeout();
        }
    }

    handleCloseClick = () => {
        const { panoramaId, setFullscreen } = this.props;
        setFullscreen(panoramaId, false);
    };

    handleOrientationClick = () => {
        const { panoramaId, setOrientationControl, orientationControl } = this.props;
        setOrientationControl(panoramaId, !orientationControl);
        this.setState({ tooltipViewed: true });
    };

    clearThankYouMessageTimeout = () => {
        if (this.thankYouMessageTimeout) {
            window.clearTimeout(this.thankYouMessageTimeout);
            this.thankYouMessageTimeout = null;
        }
    }

    startThankYouMessageTimeout = () => {
        this.clearThankYouMessageTimeout();
        this.thankYouMessageTimeout = window.setTimeout(this.handleThankYouMessageTimeout, 10000);
    }

    handleThankYouMessageTimeout = () => {
        const { setThankYouMessageState, panoramaId } = this.props;
        setThankYouMessageState(panoramaId, false);
    }

    render() {
        const {
            fullscreen,
            orientationControl,
            orientationAvailable,
            placeSelected,
            snapshotModal,
            showThankYou,
        } = this.props;
        const { tooltipViewed } = this.state;

        if (!fullscreen) return null;

        const showOrientation = orientationAvailable && !placeSelected;
        const fade = Boolean(snapshotModal);
        const showTooltip = showOrientation && !tooltipViewed;
        return (
            <Components.TopControlsContainer fade={fade}>
                <Components.OrientationControlIcon
                    onClick={this.handleOrientationClick}
                    showOrientation={showOrientation}
                    orientationControl={orientationControl}
                >
                    <Icons.OrientationControl />
                </Components.OrientationControlIcon>
                <Components.CloseIcon onClick={this.handleCloseClick}>
                    <Icons.Close />
                </Components.CloseIcon>
                <Components.OrientationControlTooltip showTooltip={showTooltip}>
                    Switch to 360 mode to use motion controls
                    <Components.OrientationControlTooltipTriangle />
                </Components.OrientationControlTooltip>
                <Components.CommentPrivacyNotification showNotification={showThankYou}>
                    Thank you for your submission
                    <br />
                    Please read our privacy policy{" "}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.transport.nsw.gov.au/online-privacy#What_information_do_we_collect">
                        here
                    </a>
                </Components.CommentPrivacyNotification>
            </Components.TopControlsContainer>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { panoramaId } = props;
    return {
        fullscreen: selectFullscreen(panoramaId, state),
        orientationControl: selectOrientationControl(panoramaId, state),
        orientationAvailable: selectOrientationAvailable(state),
        snapshotModal: selectSnapshotModal(panoramaId, state),
        showThankYou: selectThankYouMessageState(panoramaId, state),
        placeSelected: selectPlaceSelected(panoramaId, state),
    };
};
const mapDispatchToProps = {
    setFullscreen,
    setOrientationControl,
    setThankYouMessageState,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopControls);
