import styled from "react-emotion";

export const BottomBar = styled("div")(({ theme, isExpanded, fade }) => ({
    height: "auto",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    transition: "top 0.2s",
    top: fade ? "100%" : isExpanded ? "40vh" : "calc(100% - 140px)",
}));

export const PlaceSelection = styled("div")(({ theme }) => ({
    background: "rgba(237,237,237,0.48)",
    width: "100%",
    display: "flex",
    overflowX: "scroll",
    overflowY: "hidden",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "12px",
    boxSizing: "border-box",
    height: 140,
    minHeight: 140,
    WebkitOverflowScrolling: "touch",
}));

export const PlaceTile = styled("button")(({ theme, isCurrentPlace, generalTile }) => ({
    background: generalTile 
        ? isCurrentPlace ? theme.color.primary : "rgba(255,255,255,0.5)"
        : isCurrentPlace ? theme.color.highlight : theme.color.white,
    color: generalTile
        ? isCurrentPlace ? theme.color.white : theme.color.primary
        : isCurrentPlace ? theme.color.white : theme.color.primary,
    border: generalTile && !isCurrentPlace ? `1px solid ${theme.color.primary}` : "none",
    padding: "1rem",
    margin: "0.5rem",
    borderRadius: 3,
    fontFamily: theme.fontStack,
    fontSize: "1.1875rem",
    fontWeight: theme.fontWeight.medium,
    letterSpacing: 0.4,
    lineHeight: "1.5rem",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: 250,
    transition: "transform 0.2s, background 0.2s, color 0.2s",
    transformOrigin: "50% 50%",
    transform: isCurrentPlace ? "scale(1.00) !important" : "scale(1.0)",
    "&:hover": {
        transform: "scale(1.02)"
    },
}));

export const PlaceTileName = styled("label")(({ theme, isCurrentPlace }) => ({
    fontFamily: theme.fontStack,
    fontSize: "1.1875rem",
    fontWeight: theme.fontWeight.medium,
    letterSpacing: "0.4px",
    lineHeight: "1.5rem",
    textAlign: "left",
    color: isCurrentPlace ? theme.color.white : theme.color.primary,
}));

export const PlaceTileJumpLabel = styled("label")(({ theme, isCurrentPlace }) => ({
    fontFamily: theme.fontStack,
    fontSize: "0.875rem",
    fontWeight: theme.fontWeight.light,
    lineHeight: "1rem",
    textAlign: "left",
    color: isCurrentPlace ? theme.color.white : theme.color.primary,
}));

export const PlaceTileCommentCount = styled("label")(({ theme, isCurrentPlace, generalTile }) => ({
    background: generalTile
        ? isCurrentPlace ? theme.color.white : theme.color.primary
        : isCurrentPlace ? theme.color.white : theme.color.highlight,
    color: generalTile
        ? isCurrentPlace ? theme.color.primary : theme.color.white
        : isCurrentPlace ? theme.color.highlight : theme.color.white,
    fontFamily: theme.fontStack,
    fontSize: "1rem",
    fontWeight: theme.fontWeight.medium,
    textAlign: "center",
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(10px, -10px)",
    borderRadius: "50%",
    lineHeight: "40px",
    width: 40,
    height: 40,
}));

