import { normalize, denormalize, schema } from "normalizr";

const comment = new schema.Entity("comments");
const place = new schema.Entity("places", {
    comments: [comment],
});
const panorama = new schema.Entity("panoramas", {
    places: [place],
    generalComments: [comment],
});
const component = new schema.Entity("components", {
    panoramas: [panorama],
});
const upgrade = new schema.Entity("upgrade", {
    components: [component],
});

export const normalizeUpgrade = rawData => normalize(rawData, upgrade);
export const denormalizePlace = (result, entities) => denormalize(result, place, entities);
export const denormalizeUpgrade = (result, entities) => denormalize(result, upgrade, entities);
