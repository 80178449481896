import React from "react";
import { connect } from "react-redux";
import { setPanoramaCoords } from "../../ducks/panoramas";
import {
    selectCurrentPlace,
    setCurrentPlace,
    selectGeneral,
    setGeneral,
    setGeneralComment,
    selectGeneralComment,
} from "../../ducks/current-place";
import panoramaSpaceConvert from "./panorama-space-conversion";

import ThreePanoScene from "./three-scene";
import * as Components from "./panorama.style";
import { selectFullscreen, selectOrientationControl, selectIsMobile } from "../../ducks/ui";
import { selectEnv } from "../../ducks/app";

class Panorama extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        const canvasElement = this.canvasRef.current;
        const containerElement = this.containerRef.current;
        this.threeScene = new ThreePanoScene(
            canvasElement,
            containerElement,
            this.handleNewCoords,
            {
                panorama: this.props.panorama,
                distortion: this.props.distortion,
                env: this.props.env,
            }
        );
        this.threeScene.start();
    }

    componentWillUpdate(nextProps) {
        const hadAPlaceAlready = !!this.props.currentPlace;
        const haveANewPlace = !this.props.currentPlace && nextProps.currentPlace;
        const placeHasChanged =
            hadAPlaceAlready &&
            nextProps.currentPlace &&
            this.props.currentPlace.id !== nextProps.currentPlace.id;

        const newPlaceIsNull = !nextProps.currentPlace;
        if (hadAPlaceAlready && newPlaceIsNull) {
            this.stopViewingPlace();
        }

        if (this.props.generalSelected && !nextProps.generalSelected) {
            this.stopViewingPlace();
        }

        if (haveANewPlace || placeHasChanged) {
            this.viewPlace(nextProps.currentPlace);
        }



        if (!this.props.generalSelected && nextProps.generalSelected) {
            this.threeScene.controlEnabled = false;
        }

        const hadAGeneralCommentAlready = Boolean(this.props.generalComment);
        const haveANewGeneralComment = !hadAGeneralCommentAlready && nextProps.generalComment;
        const generalCommentHasChanged =
            hadAGeneralCommentAlready &&
            nextProps.generalComment &&
            this.props.generalComment.id !== nextProps.generalComment.id;
        if (haveANewGeneralComment || generalCommentHasChanged) {
            const comment = nextProps.generalComment;
            this.viewPlace({
                center_x: comment.x,
                center_y: comment.y,
            });
        }

        
    }

    componentDidUpdate(prevProps) {
        if (this.props.fullscreen !== prevProps.fullscreen) {
            this.threeScene.setFullscreen(this.props.fullscreen);
        }
        if (this.props.orientationControl !== prevProps.orientationControl) {
            this.threeScene.setDeviceOrientationControlsEnabled(this.props.orientationControl);
        }
    }

    componentWillUnmount() {
        this.threeScene.cleanup();
    }

    handleNewCoords = coords => {
        this.props.setPanoramaCoords(this.props.panoramaId, coords);
    };

    viewPlace(place) {
        const { isMobile } = this.props;
        const coords = panoramaSpaceConvert(place, isMobile);
        this.threeScene.lookAt(coords);
    }

    stopViewingPlace() {
        this.threeScene.stopViewingPlace();
    }

    handleCanvasClick = () => {
        if (this.props.currentPlace) {
            this.props.setCurrentPlace(this.props.panoramaId, null);
        }
        if (this.props.generalSelected) {
            this.threeScene.stopViewingPlace();
            this.props.setGeneral(this.props.panoramaId, false);
            this.props.setGeneralComment(this.props.panoramaId, null);
        }
    };

    render() {
        return (
            <Components.Container innerRef={this.containerRef}>
                <Components.Canvas
                    innerRef={this.canvasRef}
                    onClick={this.handleCanvasClick}
                    onTouchStart={this.handleCanvasClick}
                />
            </Components.Container>
        );
    }
}

const mapStateToProps = (state, props) => {
    const panoramaId = props.panorama.id;
    return {
        panoramaId,
        currentPlace: selectCurrentPlace(panoramaId, state),
        fullscreen: selectFullscreen(panoramaId, state),
        orientationControl: selectOrientationControl(panoramaId, state),
        isMobile: selectIsMobile(state),
        env: selectEnv(state),
        generalSelected: selectGeneral(panoramaId, state),
        generalComment: selectGeneralComment(panoramaId, state),
    };
};
const mapDispatchToProps = {
    setCurrentPlace,
    setPanoramaCoords,
    setGeneral,
    setGeneralComment,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Panorama);
