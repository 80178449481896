import makeRequest from "../modules/make-request";

import { FETCH_UPGRADE_SUCCESS } from "./upgrade";
import { SET_CURRENT_PLACE } from "./current-place";
import { selectEnv } from "./app";

const UPVOTE_COMMENT = "tfnsw-tap/comments/UPVOTE_COMMENT";
const SET_COMMENT_MODAL = "tfnsw-tap/comments/SET_COMMENT_MODAL";
const SET_SNAPSHOT_MODAL = "tfnsw-tap/comments/SET_SNAPSHOT_MODAL";
const SET_THANK_YOU_MESSAGE_STATE = "tfnsw-tap/comments/SET_THANK_YOU_MESSAGE_STATE";
export const NEW_COMMENT_REQUEST = "tfnsw-tap/comments/NEW_COMMENT_REQUEST";
export const NEW_COMMENT_SUCCESS = "tfnsw-tap/comments/NEW_COMMENT_SUCCESS";
export const NEW_COMMENT_ERROR = "tfnsw-tap/comments/NEW_COMMENT_ERROR";

const initialState = {
    commentsById: {},
    commentModals: {},
    snapshotModals: {},
    thankYouMessageState: {},
};

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case FETCH_UPGRADE_SUCCESS: {
            const { normalized } = action.payload;
            return {
                ...state,
                commentsById: normalized.entities.comments,
            };
        }
        case UPVOTE_COMMENT: {
            const { comment, upvote } = action.payload;
            return {
                ...state,
                commentsById: {
                    ...state.commentsById,
                    [comment.id]: {
                        ...state.commentsById[comment.id],
                        userVoted: upvote,
                        upvotes: state.commentsById[comment.id].upvotes + (upvote ? 1 : -1),
                    },
                },
            };
        }
        case SET_COMMENT_MODAL: {
            return {
                ...state,
                commentModals: {
                    ...state.commentModals,
                    [action.payload.panoramaId]: action.payload.modal,
                },
                thankYouMessageState: {
                    ...state.thankYouMessageState,
                    [action.payload.panoramaId]: false,
                },
            };
        }
        case SET_SNAPSHOT_MODAL: {
            return {
                ...state,
                snapshotModals: {
                    ...state.snapshotModals,
                    [action.payload.panoramaId]: action.payload.modal,
                },
                thankYouMessageState: {
                    ...state.thankYouMessageState,
                    [action.payload.panoramaId]: false,
                },
            };
        }
        case SET_THANK_YOU_MESSAGE_STATE: {
            const { panoramaId } = action.payload;
            return {
                ...state,
                thankYouMessageState: {
                    ...state.thankYouMessageState,
                    [panoramaId]: action.payload.state,
                },
            };
        }
        case SET_CURRENT_PLACE: {
            const { panoramaId } = action.payload;
            return {
                ...state,
                thankYouMessageState: {
                    ...state.thankYouMessageState,
                    [panoramaId]: action.payload.state,
                },
            };
        }
        case NEW_COMMENT_REQUEST: {
            const { comment } = action.payload;
            return {
                ...state,
                commentModals: {
                    ...state.commentModals,
                    [comment.panorama_id]: {
                        ...state.commentModals[comment.panorama_id],
                        submitting: true,
                        error: null,
                    },
                },
            };
        }
        case NEW_COMMENT_SUCCESS: {
            const { comment } = action.payload;
            return {
                ...state,
                commentModals: {
                    [comment.panorama_id]: null,
                },
                snapshotModals: {
                    [comment.panorama_id]: null,
                },
                commentsById: {
                    ...state.commentsById,
                    [comment.id]: comment,
                },
            };
        }
        case NEW_COMMENT_ERROR: {
            const { comment, error } = action.payload;

            return {
                ...state,
                commentModals: {
                    ...state.commentModals,
                    [comment.panorama_id]: {
                        ...state.commentModals[comment.panorama_id],
                        submitting: false,
                        error: error,
                    },
                },
            };
        }
        default:
            return state;
    }
}

// -----------------
// Action creators
export function setCommentUpvoteAction(comment, upvote) {
    return {
        type: UPVOTE_COMMENT,
        payload: {
            comment,
            upvote,
        },
    };
}

export function setCommentModal(panoramaId, modal) {
    return {
        type: SET_COMMENT_MODAL,
        payload: {
            panoramaId,
            modal,
        },
    };
}

export function setSnapshotModal(panoramaId, modal) {
    return {
        type: SET_SNAPSHOT_MODAL,
        payload: {
            panoramaId,
            modal,
        },
    };
}

export function setThankYouMessageState(panoramaId, state) {
    return {
        type: SET_THANK_YOU_MESSAGE_STATE,
        payload: {
            panoramaId,
            state,
        },
    };
}

export function newCommentRequestAction(comment) {
    return {
        type: NEW_COMMENT_REQUEST,
        payload: {
            comment,
        },
    };
}
export function newCommentSuccessAction(comment) {
    return {
        type: NEW_COMMENT_SUCCESS,
        payload: {
            comment,
        },
    };
}
export function newCommentErrorAction(comment, error) {
    return {
        type: NEW_COMMENT_ERROR,
        payload: {
            comment,
            error,
        },
    };
}

// -----------------
// Thunks
export function setCommentUpvote(comment, upvote) {
    return async (dispatch, getState) => {
        dispatch(setCommentUpvoteAction(comment, upvote));
        try {
            const env = selectEnv(getState());

            await makeRequest("PUT", `/comments/${comment.id}/upvote`, env, {
                upvote,
                upgrade_id: comment.upgrade_id,
            });
        } catch (error) {
            console.error(error);
        }
    };
}

export function createNewComment(comment) {
    return async (dispatch, getState) => {
        dispatch(newCommentRequestAction(comment));
        try {
            const env = selectEnv(getState());
            const res = await makeRequest("POST", `/comments/`, env, comment);
            const body = await res.json();
            dispatch(newCommentSuccessAction(body));
            if (comment.email) {
                dispatch(setThankYouMessageState(comment.panorama_id, true));
            }
            return true;
        } catch (error) {
            let message;
            if (error.errors && error.errors.length && error.errors[0].message) {
                message = error.errors[0].message;
            } else {
                message = error.toString();
            }
            dispatch(newCommentErrorAction(comment, message));
            return false;
        }
    };
}

// Selectors
export const selectComments = state => state.comments.commentsById;
export const selectCommentModal = (panoramaId, state) =>
    state.comments.commentModals[panoramaId] || null;
export const selectSnapshotModal = (panoramaId, state) =>
    state.comments.snapshotModals[panoramaId] || null;
export const selectThankYouMessageState = (panoramaId, state) =>
    state.comments.thankYouMessageState[panoramaId] || null;
