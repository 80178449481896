import checkIfMobile from "../modules/check-if-mobile";

const SET_FULLSCREEN = "tfnsw-tap/ui/SET_FULLSCREEN";
const SET_ORIENTATION_CONTROL = "tfnsw-tap/ui/SET_ORIENTATION_CONTROL";
const SET_IS_MOBILE = "tfnsw-tap/ui/SET_IS_MOBILE";
const SET_ORIENTATION_AVAILABLE = "tfnsw-tap/ui/SET_ORIENTATION_AVAILABLE";

const initialState = {
    fullscreen: { },
    orientationControl: { },
    isMobile: checkIfMobile(),
    orientationAvailable: false,
};

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_FULLSCREEN: {
            return {
                ...state,
                fullscreen: {
                    ...state.fullscreen,
                    [action.payload.panoramaId]: action.payload.fullscreen,
                }
            };
        }
        case SET_ORIENTATION_CONTROL: {
            return {
                ...state,
                orientationControl: {
                    ...state.orientationControl,
                    [action.payload.panoramaId]: action.payload.orientationControl,
                }
            };
        }
        case SET_IS_MOBILE: {
            return {
                ...state,
                isMobile: action.payload.isMobile,
            }
        }
        case SET_ORIENTATION_AVAILABLE: {
            return {
                ...state,
                orientationAvailable: action.payload.orientationAvailable,
            }
        }
        default:
            return state;
    }
}

// -----------------
// Action creators
export function setFullscreen(panoramaId, fullscreen) {
    return { type: SET_FULLSCREEN, payload: {
        panoramaId,
        fullscreen,
    } };
}
export function setOrientationControl(panoramaId, orientationControl) {
    return { type: SET_ORIENTATION_CONTROL, payload: {
        panoramaId,
        orientationControl,
    } };
}
export function setIsMobile(isMobile = checkIfMobile()) {
    return { type: SET_IS_MOBILE, payload: {
        isMobile,
    } };
}
export function setOrientationAvailable(orientationAvailable) {
    return { type: SET_ORIENTATION_AVAILABLE, payload: {
        orientationAvailable,
    } };
}


// -----------------
// Thunks


// -----------------
// Selectors
export const selectFullscreen = (panoramaId, state) => state.ui.fullscreen[panoramaId] || false;
export const selectOrientationControl = (panoramaId, state) => state.ui.orientationControl[panoramaId] || false;
export const selectIsMobile = (state) => state.ui.isMobile || false;
export const selectOrientationAvailable = (state) => state.ui.orientationAvailable || false;