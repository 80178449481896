import React from "react";
import { connect } from "react-redux";
import Icons from "../icons";
import * as Components from "./comments-section.style";
import formatDate from "../../modules/format-date";
import { setCommentUpvote, setCommentModal, setSnapshotModal } from "../../ducks/comments";
import { setGeneralComment, setGeneral } from "../../ducks/current-place";
import { selectUpgradeId } from "../../ducks/upgrade";

class CommentsSection extends React.Component {
    getUpvoteHandler = comment => () => {
        const { setCommentUpvote, upgradeId } = this.props;
        setCommentUpvote({ ...comment, upgrade_id: upgradeId }, !comment.userVoted);
    };

    renderSingleComment(comment) {
        const timestamp = comment.userCreated ? "Just now" : formatDate(comment.created_at);

        const locationLink = comment.x !== null && comment.y !== null && (
            <Components.CommentLocation
                onClick={() => {
                    this.props.setGeneralComment(this.props.panoramaId, comment);
                }}
            >
                <Icons.Crosshair />
                <span>View comment location</span>
            </Components.CommentLocation>
        );

        return (
            <Components.Comment key={comment.id}>
                <Components.CommentMetaRow>
                    <Components.CommentUserInfo>
                        <Components.CommentUsername>
                            {comment.user.username}
                        </Components.CommentUsername>
                        <Components.CommentTimestamp>{timestamp}</Components.CommentTimestamp>
                    </Components.CommentUserInfo>
                    <Components.CommentUpvotes
                        userVoted={comment.userVoted}
                        onClick={this.getUpvoteHandler(comment)}
                    >
                        <span>{comment.upvotes}</span>
                        <Icons.Thumb />
                    </Components.CommentUpvotes>
                </Components.CommentMetaRow>
                <Components.CommentContent>{comment.content}</Components.CommentContent>
                {locationLink}
            </Components.Comment>
        );
    }

    renderComments() {
        const { place } = this.props;
        if (!place) return null;
        if (place.comments && place.comments.length) {
            return (
                <Components.CommentScroll>
                    {place.comments.map(comment => this.renderSingleComment(comment))}
                </Components.CommentScroll>
            );
        } else {
            return (
                <Components.CommentsPlaceholder>
                    There are no comments yet. Be the first to make one.
                </Components.CommentsPlaceholder>
            );
        }
    }

    handleAddCommentClick = () => {
        const {
            place,
            panoramaId,
            setCommentModal,
            setSnapshotModal,
            setGeneralComment,
            setGeneral,
        } = this.props;
        if (place.general) {
            setSnapshotModal(panoramaId, { active: true });
            setGeneral(panoramaId, null);
            setGeneralComment(panoramaId, null);
        } else {
            setCommentModal(panoramaId, {
                placeId: place.id,
            });
        }
    };

    renderCommentFadeout() {
        const { place } = this.props;
        const visible = Boolean(place);
        return (
            <Components.CommentFadeout visible={visible}>
                <Components.CommentButton visible={visible} onClick={this.handleAddCommentClick}>
                    {place && place.general ? "Add general comment" : "Add comment"}
                </Components.CommentButton>
            </Components.CommentFadeout>
        );
    }

    render() {
        return (
            <Components.CommentsSection>
                {this.renderComments()}
                {this.renderCommentFadeout()}
            </Components.CommentsSection>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        upgradeId: selectUpgradeId(state),
    };
};
const mapDispatchToProps = {
    setCommentUpvote,
    setCommentModal,
    setGeneralComment,
    setSnapshotModal,
    setGeneral,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentsSection);
