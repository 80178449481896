import React from "react";
import { connect } from "react-redux";
import Icons from "../icons";
import * as Components from "./snapshot-modal.style";
import {
    selectCommentModal,
    setCommentModal,
    setSnapshotModal,
    selectSnapshotModal,
} from "../../ducks/comments";
import { selectPanoramaCoords } from "../../ducks/panoramas";
import { normalizePanoramaCoords } from "../panorama/panorama-space-conversion";

class SnapshotModal extends React.Component {
    handleExitClick = () => {
        const { panoramaId, setSnapshotModal } = this.props;
        setSnapshotModal(panoramaId, null);
    };

    handleShutterClick = () => {
        const { panoramaId, setCommentModal, panoramaCoords } = this.props;
        setCommentModal(panoramaId, {
            coords: normalizePanoramaCoords(panoramaCoords),
        });
    };

    render() {
        const { snapshotModal } = this.props;
        const visible = snapshotModal && snapshotModal.active;
        // TODO: replace with cool pulsating shutter.
        return (
            <Components.SnapshotModal visible={visible}>
                <Components.ExitButton onClick={this.handleExitClick}>
                    Exit comment mode <Icons.Close />
                </Components.ExitButton>
                <Components.ShutterTooltip>
                    <p>Move around to select an area to comment on</p>
                    <Components.ShutterTooltipTriangle />
                </Components.ShutterTooltip>
                <Components.ShutterWrapper onClick={this.handleShutterClick} visible={visible}>
                    <Icons.Shutter />
                </Components.ShutterWrapper>
                <Components.BorderHighlight />
            </Components.SnapshotModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { panoramaId }  = props;
    return {
        snapshotModal: selectSnapshotModal(panoramaId, state),
        commentModal: selectCommentModal(panoramaId, state),
        panoramaCoords: selectPanoramaCoords(panoramaId, state),
    };
};
const mapDispatchToProps = {
    setCommentModal,
    setSnapshotModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SnapshotModal);
